.nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .nav {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;
    }
}
