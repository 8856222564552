.input {
    display: block;
    font-size: var(--fs-6);
    padding: 0.5rem 0.7rem;
    border-radius: 0.6rem;
    border: none;
    outline: 1px solid #adb3b9;
    accent-color: var(--orange);
    width: 100%;
    white-space: pre;
    background: transparent;

    &__container {
        position: relative;
        width: 100%;
    }

    &__label {
        display: block;
    }

    &--invalid {
        outline-color: red;
    }

    &--valid {
        outline-color: #7ed968;
    }

    &--disabled {
        cursor: not-allowed;
        background-color: #efefef4d;
        color: #aaa;
        outline: 1px solid #adb3b9;
        pointer-events: none;
    }

    &__title {
        font-size: var(--fs-5);
        font-weight: 600;
        color: #525d6c;
    }

    &__error-text {
        cursor: pointer;
        height: 35px;
        position: absolute;
        font-size: var(--fs-7);
        color: var(--red);
        overflow: hidden;
    }

    &__file-icon {
        position: absolute;
        right: 0;
        top: 45%;
        height: 1em;
        color: var(--text-dark);
        font-size: var(--fs-3);
    }

    &[type='file']::file-selector-button {
        padding: 0.15rem 1.4rem;
        border: 1px solid inherit;
        border-radius: 1.4rem;
        outline: 1px solid inherit;
        color: #fff;
        cursor: pointer;
        transition: 0.3s;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &--primary::file-selector-button {
        background-color: var(--orange);
        border-color: var(--orange);
        outline-color: var(--orange);

        &:hover {
            color: var(--orange);
            background-color: #fff;
        }
    }

    &--admin::file-selector-button {
        background-color: var(--blue);
        border-color: var(--blue);
        outline-color: var(--blue);

        &:hover {
            color: var(--blue);
            background-color: #fff;
        }
    }
}
