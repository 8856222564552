.contest_detail {
    width: min(1000px, 90vw);
    white-space: break-spaces;

    &_header {
        padding: 55px;
        background-color: var(--orange);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        color: white;
        &_additional_container {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        &_rubrics {
            border-radius: 20px;
            background-color: #e56b44;
            padding: 5px 24px;
            width: fit-content;
            font-size: 12px;
        }

        &_icon {
            color: #ffffff;
            width: 26px;
        }

        &_suplement {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        &_title {
            margin-top: 12px;
            font-size: 36px;
            font-family: 'Gilroy-Bold';
            color: white;
        }

        &_time {
            display: block;
            margin-top: 5px;
        }

        &_btn {
        }

        &_actions_container {
            margin-top: 32px;
            margin-bottom: 12px;

            gap: 8px;
            display: flex;
        }
    }
}

.contest_description {
    width: 100%;
    &_text {
        margin-top: 20px;
    }

    &_title {
        margin-top: 20px;
        font-weight: 700;
        font-size: 18px;
        color: black;
    }

    &_text_wrapper {
        margin-top: 20px;
        white-space: break-spaces;
    }

    &_cards_list {
        margin-top: 26px;
        width: 100%;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        gap: 12px;
        &_item {
            width: 100%;
            height: 100%;
        }
    }
}

.card_content {
    &_container {
        padding: 20px !important;
        height: 100%;
    }
}

.card_header {
    display: flex;
    align-items: center;
    gap: 10px;
    h4 {
        font-family: 'Gilroy-Bold';
        color: rgb(255, 255, 255);
        font-size: 20px;
    }

    &_img {
        width: 38px;
        height: 38px;
    }
}

.card_body {
    margin-top: 10px;
    font-size: var(--fs-5);
}

.deadline_info {
    margin-top: 20px;
    white-space: break-spaces;
}

.prize_info_title {
    margin-top: 20px;
    font-weight: 700;
    color: black;
    text-transform: uppercase;
    font-size: var(--fs-4);
}

@media (max-width: 768px) {
    .contest_description {
        &_cards_list {
            margin-top: 26px;
            width: 100%;
            display: grid;
            grid-template-columns: 100%;
            gap: 12px;
            &_item {
                width: 100%;
                height: 100%;
            }
        }
    }

    .contest_detail_header_actions_container {
        flex-direction: column;
    }
}

@media (max-width: 468px) {
    .contest_detail_header {
        padding: 30px;
    }
}
