.header {
    width: 100%;
    background-color: #fff;

    &__nav-panel {
        max-width: 1210px;
        padding: 20px;
        margin: auto;
        display: grid;
        gap: 4vw;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: 0fr 0fr 1fr 0fr;
    }

    &__social-media-container {
        display: flex;
        gap: 20px;
    }

    &__social-media-icon {
        height: 1.5em;
        vertical-align: -0.125em;
        color: #525d6c;
        transition: 0.4s;

        &:hover {
            color: var(--orange);
        }
    }

    &__nav {
        display: block;
        &-list {
            display: flex;
            justify-content: center;
            gap: 5vw;

            &-item {
                color: #525d6c;
                transition: 0.4s;
                white-space: nowrap;
                &:hover {
                    color: var(--light-orange);
                }
            }
        }
        &-link--active {
            color: var(--orange);
        }
        &-link--pending {
            color: blue;
        }
    }

    &__sign-in-btn-container {
        display: flex;
        justify-content: flex-end;
    }

    &__profile-container {
        display: flex;
        justify-content: flex-end;
    }

    &__profile-redirect-link {
        color: #525d6c;
        display: flex;
        align-items: center;
        gap: 10px;
        transition: 0.3s;
        &:hover {
            color: var(--orange);
        }
    }

    &__profile-text {
        color: inherit;
        white-space: nowrap;
    }

    &__profile-icon {
        font-size: 1.4rem;
        color: inherit;
        height: 1em;
        vertical-align: -0.125em;
        opacity: 0.7;
    }
}

@media screen and (max-width: 687px) {
    .header {
        &__nav-panel {
            grid-template-columns: 0fr 0fr 1fr;
        }

        &__nav {
            display: none;
        }
    }
}
